// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-social-card-js": () => import("../src/pages/socialCard.js" /* webpackChunkName: "component---src-pages-social-card-js" */),
  "component---src-pages-type-race-js": () => import("../src/pages/typeRace.js" /* webpackChunkName: "component---src-pages-type-race-js" */)
}

